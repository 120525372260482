.inputcalendar {
  position: relative;
}
.inputcalendar::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.showonly + label {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 0.2rem;
}
.showonly:checked + label {
  background-color: orangered;
  /* background-color: white; */
}

.showonly {
  display: none;
}
